import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import PhoneComponent from '../components/PhoneComponent';
import MiniAd from '../templates/MiniAdTemplate';
import {
    Box,
    Container,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled } from '@mui/material/styles';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import screenIcon from '../images/icon-sm-screen.png';
import lensIcon from '../images/icon-sm-lens.png';
import chipIcon from '../images/icon-sm-chip.png';
import storageIcon from '../images/icon-sm-storage.png';
import colorIcon from '../images/icon-sm-colors.png';
import batteryIcon from '../images/icon-sm-battery.png';
import sanitizeHtml from 'sanitize-html';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const StyledGrid = styled(Grid)({
    backgroundColor: '#F0F3FF',
    marginTop: '1em',
    padding: '20px',
    marginLeft: '0',
    width: '100%',
});

const RoundedGrid = styled(Grid)({
    backgroundColor: '#F0F3FF',
    marginTop: '1em',
    padding: '20px',
    borderRadius: '0.5em',
});

const StyledAccordion = styled(Accordion)({
    borderBottom: '1px solid rgb(213, 214, 218)',
    marginBottom: '0px',
    marginTop: '0px',
    borderRadius: '0px !important',
});

const renderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.HEADING_3]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const whatsInBoxRenderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.HEADING_3]: (node, children) => (
            <Box
                component="h3"
                sx={{
                    fontFamily: 'Apercu',
                    fontSize: '22px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    margin: '10px 0 15px',
                }}
            >
                {children}
            </Box>
        ),
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const accordionRenderingOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Box component="p">
                {children}
                <br />
            </Box>
        ),
        [BLOCKS.HEADING_3]: (node, children) => <Box component="p">{children}</Box>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const DescriptionRow = ({ title, description }) => (
    <Grid container xs={12} mt={1}>
        <Grid xs={3} display="flex" sx={{ mr: '22px' }}>
            <Typography align="right" width={1} sx={{ fontWeight: '600', lineHeight: '1.1' }}>
                {title}
            </Typography>
        </Grid>
        <Grid xs={7}>
            <Typography align="left">{description}</Typography>
        </Grid>
    </Grid>
);

const Specs = ({ alt, logo, text }) => (
    <Grid container width={1}>
        <Grid xs={2}>
            <img src={logo} alt={alt} height="24" width="24" />
        </Grid>
        <Grid xs={10} pl={2}>
            <Typography variant="span" sx={{ fontSize: '14px', lineHeight: '13px' }}>
                {text}
            </Typography>
        </Grid>
    </Grid>
);

const CenteredImageGrid = ({ url, alt, credits }) => (
    <Grid container spacing={0}>
        <Grid xs={12} display="flex" justifyContent="center" alighnItems="center">
            <GatsbyImage
                image={getImage(url)}
                objectFit="contain"
                style={{
                    height: '16rem',
                }}
                alt={alt || 'review'}
            />
        </Grid>

        <Grid xs={12} sx={{ mt: '10px' }}>
            <Typography>{credits}</Typography>
        </Grid>
    </Grid>
);

const ReviewAccordion = ({ title, nodeRichText }) => (
    <StyledAccordion sx={{ boxShadow: '0' }}>
        <AccordionSummary aria-controls="panel1d-content" expandIcon={<ExpandMoreIcon />}>
            <Typography
                fontWeight="600"
                sx={{
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '19px',
                }}
            >
                {title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>{renderRichText(nodeRichText, accordionRenderingOptions)}</AccordionDetails>
    </StyledAccordion>
);

class ReviewTemplate extends React.Component {
    render() {
        // const isMobile = sanitizeHtml(this.props.isMobile);
        // const isSmall = sanitizeHtml(this.props.isSmall);
        const post = get(this.props, 'data.reviewData');
        const metaTags = get(this.props, 'data.metaTags');

        const reviewDescription = post && post.reviewDescription ? sanitizeHtml(post.reviewDescription) : '';
        // const reviewMediaUrl = post && post.reviewVideo ? sanitizeHtml(post.reviewVideo) : '';
        const reviewMediaTitle = post && post.reviewVideo ? sanitizeHtml(post.reviewVideo.title) : '';
        const imageCredits = post && post.imageCredits ? sanitizeHtml(post.imageCredits) : '';
        const reviewPrice = post && post.reviewPrice ? sanitizeHtml(post.reviewPrice) : '';
        const whatsNew = post && post.reviewWhatsNew ? sanitizeHtml(post.reviewWhatsNew) : '';
        const releaseDate = post && post.reviewReleaseDate ? sanitizeHtml(post.reviewReleaseDate) : '';
        const rate = post && post.reviewAsurionRate ? post.reviewAsurionRate : '';
        // const pros = post && post.reviewLikes ? sanitizeHtml(post.reviewLikes) : '';
        // const cons = post && post.reviewDislikes ? sanitizeHtml(post.reviewDislikes) : '';
        // const bottomLines = post && post.reviewBottomLines ? sanitizeHtml(post.reviewBottomLines) : '';

        const pros = post && post.reviewLikes ? post.reviewLikes : '';
        const cons = post && post.reviewDislikes ? post.reviewDislikes : '';
        const bottomLines = post && post.reviewBottomLines ? post.reviewBottomLines : '';

        const reviewImagesUrl = post && post.reviewImages ? sanitizeHtml(post.reviewImages.file.url) : '';
        const reviewImagesFile = post && post.reviewImages ? sanitizeHtml(post.reviewImages.file.fileName) : '';

        const reviewDeviceSpecTitle =
            post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpecTitle) : '';
        const deviceSpecsScreen = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.screen) : '';
        const deviceSpecsPower = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.power) : '';
        const deviceSpecsRam = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.ram) : '';
        const deviceSpecsBattery =
            post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.battery) : '';
        const deviceSpecsDisk = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.disk) : '';
        const deviceSpecsColor = post && post.reviewDeviceSpecTitle ? sanitizeHtml(post.reviewDeviceSpec.color) : '';

        //const whatsInTheBoxImageUrl = post && post.whatsInTheBoxImage ? post.whatsInTheBoxImage.file.url : '';
        const whatsInTheBoxImageUrl = post && post.whatsInTheBoxImage ? post.whatsInTheBoxImage : '';

        const whatsInTheBoxImageFile = post && post.whatsInTheBoxImage ? post.whatsInTheBoxImage.file.fileName : '';
        const reviewWhatsInTheBoxBelowBanner =
            post && post.reviewWhatsInTheBoxBelowBanner ? post.reviewWhatsInTheBoxBelowBanner : '';

        const designAndDisplay = post && post.designAndDisplay ? post.designAndDisplay : '';
        const camera = post && post.camera ? post.camera : '';
        const battery = post && post.battery ? post.battery : '';
        const affordability = post && post.affordability ? post.affordability : '';

        const postTitle = post && post.title ? post.title : '';
        const postBrandName = post && post.brandName ? post.brandName : '';
        const postPrice = post && post.adBlockDynamicPrice ? post.adBlockDynamicPrice : '';
        const postPromoText = post && post.adBlockPromoText ? post.adBlockPromoText : '';
        const postDescription = post && post.adBlockDescription ? post.adBlockDescription : '';

        const metaTitle = postTitle + ' | Reviews | Asurion Mobile+';
        const metaDescription = metaTags && metaTags.description ? metaTags.description : '';
        const ogImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

        // const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
        const domainUrl = '';
        const canonicalUrl =
            this.props && this.props.pageContext && this.props.pageContext.canonicalPath
                ? domainUrl + this.props.pageContext.canonicalPath
                : domainUrl + this.props.path;

        console.log('canonical', canonicalUrl);

        return (
            <PhoneComponent
                postTitle={postTitle}
                brandName={postBrandName}
                adBlockDynamicPrice={postPrice}
                adBlockPromoText={postPromoText}
                adBlockDescription={postDescription}
                activeTab={'review'}
                canonicalUrl={canonicalUrl}
                subcomponent={
                    <>
                        <Seo
                            title={metaTitle}
                            description={metaDescription}
                            ogImage={ogImage}
                            image={ogImage}
                            canonical={canonicalUrl}
                        />

                        <Container maxWidth="ssm" fixed className="ltArticleContainer">
                            <Grid
                                container
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid display="flex" justifyContent="center" sx={{ mb: 2, mt: 2 }}>
                                    <Typography sx={{ fontSize: '16px' }}>{reviewDescription}</Typography>
                                </Grid>

                                <CenteredImageGrid
                                    url={post.reviewVideo}
                                    alt={reviewMediaTitle}
                                    credits={imageCredits}
                                />

                                <StyledGrid container>
                                    <DescriptionRow title="Release" description={releaseDate}></DescriptionRow>
                                    <DescriptionRow title="Price" description={reviewPrice}></DescriptionRow>
                                    <DescriptionRow title="What's New" description={whatsNew}></DescriptionRow>
                                    {rate && <DescriptionRow title="Rating" description={rate}></DescriptionRow>}
                                </StyledGrid>

                                <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2} width={1} mt={2}>
                                    <RoundedGrid sx={{ marginTop: '0' }} gridColumn={{ xs: 'span 2', md: 'span 1' }}>
                                        {renderRichText(pros, renderingOptions)}
                                    </RoundedGrid>
                                    <RoundedGrid sx={{ marginTop: '0' }} gridColumn={{ xs: 'span 2', md: 'span 1' }}>
                                        {renderRichText(cons, renderingOptions)}
                                    </RoundedGrid>
                                </Box>

                                {bottomLines && (
                                    <RoundedGrid item xs={12} mb={3}>
                                        {renderRichText(bottomLines, renderingOptions)}
                                    </RoundedGrid>
                                )}

                                <CenteredImageGrid
                                    url={post.reviewImages}
                                    alt={reviewImagesFile}
                                    credits={imageCredits}
                                />

                                <StyledGrid
                                    container
                                    spacing={1}
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item display="flex" justifyContent="center" xs={12} sx={{ mb: '24px' }}>
                                        <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }}>
                                            {reviewDeviceSpecTitle}
                                        </Typography>
                                    </Grid>

                                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                                        <Specs alt="specs screen" logo={screenIcon} text={deviceSpecsScreen} />
                                        <Specs alt="specs power" logo={lensIcon} text={deviceSpecsPower} />
                                        <Specs alt="specs ram" logo={chipIcon} text={deviceSpecsRam} />
                                        <Specs alt="specs battery" logo={batteryIcon} text={deviceSpecsBattery} />
                                        <Specs alt="specs disk" logo={storageIcon} text={deviceSpecsDisk} />
                                        <Specs alt="specs color" logo={colorIcon} text={deviceSpecsColor} />
                                    </Box>
                                </StyledGrid>

                                <CenteredImageGrid
                                    url={whatsInTheBoxImageUrl}
                                    alt={whatsInTheBoxImageFile}
                                    credits={imageCredits}
                                />

                                <StyledGrid>
                                    {renderRichText(reviewWhatsInTheBoxBelowBanner, whatsInBoxRenderingOptions)}
                                </StyledGrid>

                                <Grid item xs={12}>
                                    <MiniAd>
                                        <Typography sx={{ fontSize: '16px', lineHeight: '16px' }}>
                                            20% of Australians will severely damage or lose their phone this year.{' '}
                                        </Typography>
                                    </MiniAd>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                        <ReviewAccordion
                                            title="Design and Display"
                                            nodeRichText={designAndDisplay}
                                        ></ReviewAccordion>
                                    </Grid>
                                    <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                        <ReviewAccordion title="Camera" nodeRichText={camera}></ReviewAccordion>
                                    </Grid>
                                    <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                        <ReviewAccordion title="Battery" nodeRichText={battery}></ReviewAccordion>
                                    </Grid>
                                    <Grid item xs={{ marginTop: '0px', marginBottom: '0px' }}>
                                        <ReviewAccordion
                                            title="Value for Money"
                                            nodeRichText={affordability}
                                        ></ReviewAccordion>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                }
            />
        );
    }
}

export default function ReviewMainTemplate(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('lg'));

    console.log('isMObile', isMobile);
    return <ReviewTemplate {...props} theme={theme} isMobile={isMobile} isSmall={isSmall} />;
}

export const reviewPageQuery = graphql`
    query reviewQuery($title: String, $metaTagTitle: String) {
        reviewData: contentfulDeviceDetailPage(title: { eq: $title }) {
            brand
            brandName
            title
            reviewPrice
            reviewDescription
            adBlockDynamicPrice
            adBlockPromoText {
                raw
            }
            adBlockDescription {
                raw
            }
            camera {
                raw
            }
            battery {
                raw
            }
            designAndDisplay {
                raw
            }
            affordability {
                raw
            }
            reviewVideo {
                title
                file {
                    fileName
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 256)
            }
            imageCredits
            reviewReleaseDate
            reviewWhatsNew
            reviewLikes {
                raw
            }
            reviewDislikes {
                raw
            }
            reviewBottomLines {
                raw
            }
            reviewImages {
                title
                file {
                    fileName
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 256)
            }
            reviewDeviceSpecTitle
            reviewDeviceSpec {
                title
                screen
                battery
                power
                disk
                ram
                color
            }
            reviewWhatsInTheBox {
                raw
            }
            reviewWhatsInTheBoxBelowBanner {
                raw
            }
            whatsInTheBoxImage {
                title
                file {
                    fileName
                    url
                }
                gatsbyImageData(layout: CONSTRAINED, resizingBehavior: SCALE, height: 256)
            }
        }
        metaTags: contentfulMetaTags(title: { eq: $metaTagTitle }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
    }
`;
